import React from 'react';
import logo from './logo.svg';
import './App.css';
import FeatureBlock from './feature_block';

function App() {
  return (
    <div className="App">
      <div class="panel-left">
        <div id="title">
          Latte Text
        </div>
        <div id="description">
Latte Text is a stunning <span>text editor</span> on iPhone and iPad. Its purpose is to give you almost the same experience as you're using your editor on computer. If you are using Latte Text on you iPad with a Bluetooth connected keyboard, you are changing your iPad into a useful productive device other than just an entertaining device. 
        </div>
        <div id="feature">
          <div class="feature_line">
            <FeatureBlock>$ It’s a highly customizable text editor.</FeatureBlock>
            <FeatureBlock>$ It supports more than 100 languages.</FeatureBlock>
            <FeatureBlock last={true}>$ Bluetooth key-board supported.</FeatureBlock>
          </div>
          <div class="feature_line">
            <FeatureBlock>$ Integrate with GitHub reposi-tories.</FeatureBlock>
            <FeatureBlock>$ Hotkeys make writing much easier.</FeatureBlock>
            <FeatureBlock last={true}>$ Fit for both portrait & horizontal</FeatureBlock>
          </div>
        </div>
        
        <div id="appstore">
          <a href="https://apps.apple.com/us/app/latte-text/id1475299021">
            <img alt="Latte Text on AppStore" src={window.location.origin + '/appstore.png'} />
          </a>
        </div>
      </div>

      <div class="panel-right" id="device">
        <img alt="" src={window.location.origin + '/device.png'} />
      </div>
    </div>
  );
}

export default App;
