import React from 'react';

export default class FeatureBlock extends React.Component {
  render() {
    if (this.props.last) {
      return (
        <div class="feature_block last">
          {this.props.children}
        </div>
      )
    } else {
      return (
        <div class="feature_block not_last">
          {this.props.children}
        </div>
      )
    }
  }
}
